<template>
    <div class="content-wrapper" :class="{ 'rq-container': includeAccountNumber }">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
        />
        <div class="row">
            <div class="col-2 form-group">
                <b-form-checkbox
                    automation_id="chk_send_once"
                    id="chk_send_once"
                    :disabled="readOnly"
                    v-model="positivePayLocal.sendOnce">Send Once</b-form-checkbox>
            </div>
            <div class="col-2 form-group">
                <b-form-checkbox
                    automation_id="chk_use_issue_time"
                    id="chk_use_issue_time"
                    :disabled="readOnly"
                    v-model="positivePayLocal.useIssueTime">Use Issue Time</b-form-checkbox>
            </div>
            <!-- RQO-19053: hidden rather than removed in case of future use -->
            <!-- <div class="col-2 form-group">
                <b-form-checkbox
                    automation_id="chk_show_report"
                    id="chk_show_report"
                    v-model="positivePayLocal.showReport">Detail Report</b-form-checkbox>
            </div>
            <div class="col-2 form-group">
                <b-form-checkbox
                    automation_id="chk_summary_report"
                    id="chk_summary_report"
                    v-model="positivePayLocal.summaryReport">Summary Report</b-form-checkbox>
            </div> -->
            <div class="col col-auto ms-auto form-group">
                <b-button
                    automation_id="btn_validate_settings"
                    ref="btn_validate_settings"
                    variant="theme"
                    size="sm"
                    :disabled="!hasExportFormat || readOnly"
                    @click="onValidatePositivePay"
                    v-rq-tooltip.hover.top="{ title: `Validate Settings` }">Validate Settings
                </b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-2 form-group mt-3">
                <b-form-checkbox
                    automation_id="chk_make_unique_name"
                    id="chk_make_unique_name"
                    v-model="positivePayLocal.makeUniqueName">Make FileName Unique</b-form-checkbox>
            </div>
            <div v-if="positivePayLocal.makeUniqueName"
                :class="{ 'col-4 form-group form-required': true, 'has-error': v$.positivePayLocal.uniqueFileNameExtension.$error }">
                <label for="drp_unique_filename_extension">Unique FileName Extension</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_unique_filename_extension', id: 'drp_unique_filename_extension' }"
                    :items="[{name: '.csv'}, {name: '.txt'}]"
                    value-expr="name"
                    display-expr="name"
                    :disabled="readOnly"
                    v-model="v$.positivePayLocal.uniqueFileNameExtension.$model"
                />
                <rq-validation-feedback>FileName Extension is required.</rq-validation-feedback>
            </div>
            <div v-else
                :class="{ 'col-4 form-group': true, 'form-required': isExportFileNameRequired, 'has-error': v$.positivePayLocal.exportFileName.$error }">
                <label for="txt_export_filename">Export Filename</label>
                <input
                    id="txt_export_filename"
                    automation_id="txt_export_filename"
                    type="text"
                    class="form-control"
                    placeholder="Export Filename"
                    maxlength="100"
                    :disabled="readOnly"
                    v-model="v$.positivePayLocal.exportFileName.$model"
                >
                <rq-validation-feedback
                    :messages="{
                        'Export FileName is required if [Make FileName Unique] is unchecked.': !isCompanyEdit && v$.positivePayLocal.exportFileName.required.$invalid,
                        'Export FileName is required if [Export Format] is populated.': isCompanyEdit && v$.positivePayLocal.exportFileName.required.$invalid
                    }"
                />
            </div>
            <div :class="{ 'col-6 form-group': true, 'form-required': isExportFormatRequired, 'has-error': v$.positivePayLocal.exportFormat.$error }">
                <label for="drp_unique_filename_extension">Export Format</label>
                <div class="input-group" >
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_export_format', id: 'drp_export_format' }"
                        :items="exportFormats"
                        value-expr="id"
                        display-expr="name"
                        :disabled="readOnly"
                        v-model="v$.positivePayLocal.exportFormat.$model"
                        @value-changed="onExportFormatChange"
                    />
                    <b-btn v-rq-tooltip.hover.top title="Search Export Formats"
                        variant="theme"
                        :disabled="readOnly"
                        @click="onExportFormatPicker">
                        <FontAwesomeIcon icon="fa fa-search" aria-hidden="true" />
                    </b-btn>
                </div>
                <rq-validation-feedback :offset="60"
                    :messages="{
                        'Export Format is required.': !isCompanyEdit && v$.positivePayLocal.exportFormat.required.$invalid,
                        'Export Format is required if [Export FileName] is populated.': isCompanyEdit && v$.positivePayLocal.exportFormat.required.$invalid
                    }"
                />
            </div>
        </div>
        <div class="row">
            <div v-if="includeAccountNumber"
                :class="{ 'col-4 form-group form-required': true, 'has-error': v$.positivePayLocal.accountNumber.$error }">
                <label for="txt_account_number">Bank Account Number</label>
                <input
                    id="txt_account_number"
                    automation_id="txt_account_number"
                    type="text"
                    class="form-control"
                    placeholder="Account Number"
                    :value="positivePayLocal.accountNumber"
                    maxlength="40"
                    v-rq-tooltip.hover.top
                    title="Account Number is required and can be updated by an Administrator in Configuration."
                    disabled
                >
                <rq-validation-feedback>Bank Account Number is required.</rq-validation-feedback>
            </div>
            <div :class="{ 'form-group':true, 'col-8': !includeAccountNumber, 'col-4': includeAccountNumber, 'form-required': hasExportFormat && info1Required, 'has-error': hasExportFormat && v$.positivePayLocal.info1.$error }">
                <label for="txt_info1">Additional Field 1</label>
                <input
                    id="txt_info1"
                    automation_id="txt_info1"
                    type="text"
                    class="form-control"
                    placeholder="Additional Field 1"
                    maxlength="200"
                    :disabled="!hasExportFormat || readOnly"
                    v-model="v$.positivePayLocal.info1.$model"
                >
                <rq-validation-feedback>{{ this.info1Message }}</rq-validation-feedback>
            </div>
            <div :class="{ 'col-4 form-group':true, 'form-required': hasExportFormat && info2Required, 'has-error': hasExportFormat && v$.positivePayLocal.info2.$error }">
                <label for="txt_info2">Additional Field 2</label>
                <input
                    id="txt_info2"
                    automation_id="txt_info2"
                    type="text"
                    class="form-control"
                    placeholder="Additional Field 2"
                    maxlength="50"
                    :disabled="!hasExportFormat || readOnly"
                    v-model="v$.positivePayLocal.info2.$model"
                >
                <rq-validation-feedback>{{ this.info2Message }}</rq-validation-feedback>
            </div>
        </div>
        <div class="row">
            <div :class="{ 'col-4 form-group':true, 'form-required': hasExportFormat && info3Required, 'has-error': hasExportFormat && v$.positivePayLocal.info3.$error }">
                <label for="txt_info3">Additional Field 3</label>
                <input
                    id="txt_info3"
                    automation_id="txt_info3"
                    type="text"
                    class="form-control"
                    placeholder="Additional Field 3"
                    maxlength="50"
                    :disabled="!hasExportFormat || readOnly"
                    v-model="v$.positivePayLocal.info3.$model"
                >
                <rq-validation-feedback>{{ this.info3Message }}</rq-validation-feedback>
            </div>
            <div :class="{ 'col-4 form-group':true, 'form-required': hasExportFormat && info4Required, 'has-error': hasExportFormat && v$.positivePayLocal.info4.$error }">
                <label for="txt_info4">Additional Field 4</label>
                <input
                    id="txt_info4"
                    automation_id="txt_info4"
                    type="text"
                    class="form-control"
                    placeholder="Additional Field 4"
                    maxlength="50"
                    :disabled="!hasExportFormat || readOnly"
                    v-model="v$.positivePayLocal.info4.$model"
                >
                <rq-validation-feedback>{{ this.info4Message }}</rq-validation-feedback>
            </div>
            <div :class="{ 'col-4 form-group':true, 'form-required': hasExportFormat && info5Required, 'has-error': hasExportFormat && v$.positivePayLocal.info5.$error }">
                <label for="txt_info5">Additional Field 5</label>
                <input
                    id="txt_info5"
                    automation_id="txt_info5"
                    type="text"
                    class="form-control"
                    placeholder="Additional Field 5"
                    maxlength="50"
                    :disabled="!hasExportFormat || readOnly"
                    v-model="v$.positivePayLocal.info5.$model"
                >
                <rq-validation-feedback>{{ this.info5Message }}</rq-validation-feedback>
            </div>
        </div>
    </div>
</template>
<script>
    import { useDeepTwoWayBinding } from "@/shared/composables/useDeepTwoWayBinding";
    import { PositivePay, PositivePayValidationResultDto } from '../../../escrow-accounting/models'
    import PositivePayExportFormatViewer from '@config/checkWriting/components/PositivePayExportFormatViewer';
    import { requiredIf } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";

    export default {
        props: {
            bank: { type: Object, required: false, default: () => ({}) },
            positivePay: { type: Object, required: true, default: () => ({}) },
            exportFormats: { type: Array, required: true, default: () => [] },
            includeAccountNumber: { type: Boolean, required: false, default: false },
            isCompanyEdit: { type: Boolean, required: false, default: false }
        },
        emits: ["update:positivePay"],
        setup(props, { emit }) {
            const v$ = useVuelidate();
            const localRefs = useDeepTwoWayBinding(props, emit, {
                positivePayLocal: "positivePay"
            });
            return {
                v$,
                ...localRefs
            };
        },
        data () {
            return {
                showBanner: false,
                info1Message: "",
                info1Required: false,
                info2Message: "",
                info2Required: false,
                info3Message: "",
                info3Required: false,
                info4Message: "",
                info4Required: false,
                info5Message: "",
                info5Required: false,
                originalPositivePay: { },
            };
        },
        computed: {
            hasExportFileName() { return !!this.positivePayLocal.exportFileName; },
            hasExportFormat() { return _.getNumber(this, "positivePayLocal.exportFormat", 0) > 0 },
            isExportFileNameRequired() { return (this.isCompanyEdit) ? this.hasExportFormat : true; },
            isExportFormatRequired() { return (this.isCompanyEdit) ? this.hasExportFileName : true; },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "CanEditPositivePay",
                ]);
            },
            readOnly() { return !this.localSecurity.CanEditPositivePay && !this.localSecurity.IsAdmin && !this.localSecurity.IsEscrowAdmin; },
        },
        validations() {
            const self = this;
            return {
                positivePayLocal: {
                    accountNumber: {
                        required: requiredIf(() => self.includeAccountNumber)
                    },
                    uniqueFileNameExtension: {
                        required: requiredIf(() => self.positivePayLocal.makeUniqueName)
                    },
                    exportFormat: {
                        required: requiredIf(() => !self.isCompanyEdit || self.hasExportFileName)
                    },
                    exportFileName: {
                        required: requiredIf(() => !self.positivePayLocal.makeUniqueName && (!self.isCompanyEdit || self.hasExportFormat))
                    },
                    info1: {
                        required: requiredIf(() => self.info1Required)
                    },
                    info2: {
                        required: requiredIf(() => self.info2Required)
                    },
                    info3: {
                        required: requiredIf(() => self.info3Required)
                    },
                    info4: {
                        required: requiredIf(() => self.info4Required)
                    },
                    info5: {
                        required: requiredIf(() => self.info5Required)
                    },
                }
            };
        },
        created() {
            this.originalPositivePay = _.clone(this.positivePay);
            this.getPositivePayValidationSettings();
        },
        methods: {
            getPositivePayValidationSettings() {
                const self = this;
                if (_.parseNumber(self.positivePayLocal.exportFormat, 0) == 0) return;
                let apiPromise = self.$api.EscrowAccountingApi.getPositivePayValidationSettings(self.positivePayLocal.exportFormat);
                self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.setValidationData(new PositivePayValidationResultDto(data));
                    })
                    .catch(error => {
                        self.$toast.error("An issue occurred while retrieving Positive Pay Settings.");
                        console.error(error);
                    });
            },
            onExportFormatChange(e) {
                if(e.value === this.originalPositivePay.exportFormat) {
                    this.setInfoFields(this.originalPositivePay);
                }
                else {
                    this.setInfoFields();
                }
                this.getPositivePayValidationSettings();
            },
            onExportFormatPicker(e) {
                this.showPositivePayExportPickerDialog();
            },
            onValidatePositivePay(e) {
                const self = this;
                let positivePay = new PositivePay(self.positivePayLocal);
                self.validatePositivePay(positivePay)
                    .then(data => {
                        self.$toast.success("Positive Pay Settings Validated.");
                    })
                    .catch(error => {
                        self.$toast.error("An issue occurred while validating Positive Pay Settings.");
                        console.error(error);
                    });

            },
            setInfoFields(item=null) {
                const self = this;
                for(let num=1; num <= 5; num++) {
                    let infoField = `info${num}`;
                    if(_.isNil(item)) {
                        self.positivePayLocal[infoField] = "";
                        continue;
                    }
                    self.positivePayLocal[infoField] = item[infoField];
                }
            },
            async setValidationData(data) {
                const self = this;
                for(let num=1; num <= 5; num++) {
                    let reqField = `info${num}Required`;
                    let msgField = `info${num}Message`;

                    self[reqField] = data[reqField];
                    self[msgField] = data[msgField];
                }

                await self.$nextTick();
                self.v$.$touch();
            },
            validatePositivePay(positivePay) {
                const self = this;
                self.errorMessage = "";
                if (!self.includeAccountNumber && !_.isEmpty(self.bank)) {
                    positivePay.accountNumber = self.bank.accountNumber;
                }
                let apiPromise = self.$api.EscrowAccountingApi.validatePositivePay(positivePay);
                return self.$rqBusy.wait(apiPromise);
            },
            validate() {
                const self = this;
                self.v$.$touch();
                return !self.v$.$error;
            },
            async save(){
                const self = this;
                if (self.readOnly || !self.localSecurity.CanEditPositivePay) return;

                self.v$.$touch();
                self.hasErrorLocal = self.v$.$error;
                self.showBanner = self.v$.$error && !self.isCompanyEdit;
                if (self.v$.$error) return false;

                let item = new PositivePay(self.positivePayLocal);
                try {
                    await self.validatePositivePay(item);
                    let changes = self.getAuditChanges(self.originalPositivePay, self.positivePayLocal);
                    if (changes.length === 0) return item;

                    let apiPromise = self.$api.EscrowAccountingApi.savePositivePay(item, changes);
                    return await self.$rqBusy.wait(apiPromise);
                }
                catch(err) {
                    self.$toast.error("An issue occurred while saving Positive Pay Settings.");
                    console.error(err);
                    return false;
                }
            },
            showPositivePayExportPickerDialog() {
                const self = this;
                self.$dialog.open({
                    title: `Positive Pay Export Format Viewer`,
                    height: "600",
                    width: "800",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: PositivePayExportFormatViewer,
                    props: {
                        exportFormatID: self.positivePayLocal.exportFormat,
                        showHeader: false
                    },
                    onOk: (e) => {
                        self.positivePayLocal.exportFormat = e.component.selectedExportFormatID;
                        return true;
                    }
                });
            },
        }
    }
</script>
