<template>
    <rq-page-section title="Escrow Accounts" borderless>
        <rq-section-card title="Account Details" section-group="company-edit" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_account_number">Account Number</label>
                        <input id="txt_account_number" automation_id="txt_account_number" type="text" class="form-control" placeholder="Account Number" v-model="bankLocal.accountNumber" maxlength="40">
                    </div>
                    <div class="col-6 form-group">
                        <label for="txt_deposit_slip_routing">Deposit Slip Routing Number</label>
                        <input id="txt_deposit_slip_routing" automation_id="txt_deposit_slip_routing" type="text" class="form-control" placeholder="Deposit Slip Routing Number" v-model="bankLocal.depositSlipRoutingNumber" maxlength="40">
                    </div>

                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_bank_number">Routing Number</label>
                        <input id="txt_bank_number" automation_id="txt_bank_number" type="text" class="form-control" placeholder="Bank Number" v-model="bankLocal.bankInfoNumber" maxlength="40">
                    </div>
                    <div class="col-6 form-group">
                        <label for="txt_wire_transfer_routing">Wire Transfer Routing Number</label>
                        <input id="txt_wire_transfer_routing" automation_id="txt_wire_transfer_routing" type="text" class="form-control" placeholder="Wire Transfer Routing Number" v-model="bankLocal.wireTransferRoutingNumber" maxlength="40">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_fractional_number">Fractional Number</label>
                        <input id="txt_fractional_number" automation_id="txt_fractional_number" type="text" class="form-control" placeholder="Fractional Number" v-model="bankLocal.fractionalNumber" maxlength="40">
                    </div>
                </div>

            </fieldset>
        </rq-section-card>
        <rq-section-card title="Check &amp; Deposit Slip Format Details" section-group="company-edit" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_check_template">Check Template</label>
                        <input id="txt_check_template" automation_id="txt_check_template" type="text" class="form-control" placeholder="Check Template" v-model="bankLocal.checkTemplateFile">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_lines_per_check"># of Lines per Check Stub</label>
                        <rqInputNumber
                            id="txt_lines_per_check"
                            automation_id="txt_lines_per_check"
                            defaultValue="1"
                            minValue="1"
                            decimals="0"
                            placeholder="# of Lines per Check Stub"
                            v-model="bankLocal.checkLineLimit"/>
                    </div>
                    <div class="col-6 form-group">
                        <label for="txt_check_no_characters">Check # Characters</label>
                        <rqInputNumber
                            id="txt_check_no_characters"
                            automation_id="txt_check_no_characters"
                            defaultValue="0"
                            minValue="0"
                            decimals="0"
                            placeholder="Check # Characters"
                            v-model="bankLocal.checkDigitsLength"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_check_range_start">Check Range Start</label>
                        <rqInputNumber
                            id="txt_check_range_start"
                            automation_id="txt_check_range_start"
                            defaultValue="0"
                            minValue="0"
                            maxValue="999999999999999"
                            decimals="0"
                            :commas="false"
                            placeholder="Check Range Start"
                            v-model="bankLocal.checkRangeStart"/>
                    </div>
                    <div class="col-6 form-group">
                        <label for="txt_check_range_end">Check Range End</label>
                        <rqInputNumber
                            id="txt_check_range_end"
                            automation_id="txt_check_range_end"
                            defaultValue="0"
                            minValue="0"
                            maxValue="999999999999999"
                            decimals="0"
                            :commas="false"
                            placeholder="Check Range End"
                            v-model="bankLocal.checkRangeEnd"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_deposit_template">Deposit Template</label>
                        <input id="txt_deposit_template" automation_id="txt_deposit_template" type="text" class="form-control" placeholder="Deposit Template" v-model="bankLocal.depositSlipTemplateFile" maxlength="70">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_lines_per_deposit"># of Lines per Deposit Slip</label>
                        <rqInputNumber
                            id="txt_lines_per_deposit"
                            automation_id="txt_lines_per_deposit"
                            defaultValue="1"
                            minValue="1"
                            decimals="0"
                            placeholder="# of Lines per Deposit Slip"
                            v-model="bankLocal.depositSlipLineLimit"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 form-group">
                        <label for="txt_bank_info">Bank Information for MICR</label>
                        <textarea id="txt_bank_info" automation_id="txt_bank_info" type="text" class="form-control" placeholder="Bank Information for MICR" v-model="bankLocal.bankInfo" rows="4"></textarea>
                    </div>
                    <div class="col-6 form-group">
                        <label for="txt_company_info">Company Information for MICR</label>
                        <textarea id="txt_company_info" automation_id="txt_company_info" type="text" class="form-control" placeholder="Company Information for MICR" v-model="bankLocal.companyInfo" rows="4"></textarea>
                    </div>
                </div>
            </fieldset>
        </rq-section-card>
        <rq-section-card title="Positive Pay" section-group="company-edit" collapsible>
            <positive-pay-info
                ref="positivePayComponent"
                :export-formats="exportFormats"
                v-model:bank="bankLocal"
                v-model:positive-pay="positivePayLocal"
                is-company-edit
            />
        </rq-section-card>
    </rq-page-section>
</template>
<script setup>
    import { ref } from "vue";
    import { useDeepTwoWayBinding } from "@/shared/composables/useDeepTwoWayBinding";
    import PositivePayInfo from "./PositivePayInfo";

    const props = defineProps({
        bank: { type: Object, required: true, default: () => ({}) },
        positivePay: { type: Object, required: true, default: () => ({}) },
        exportFormats: { type: Array, required: true, default: () => [] },
        readOnly: { type: Boolean, default: false }
    });

    const emit = defineEmits([
        "update:bank",
        "update:positivePay",
        "update:hasError"
    ]);

    const positivePayComponent = ref(null);

    const { positivePayLocal, bankLocal } = useDeepTwoWayBinding(props, emit, {
        positivePayLocal: "positivePay",
        bankLocal: "bank"
    });

    const validate = () => positivePayComponent.value.validate();

    defineExpose({ validate });
</script>
