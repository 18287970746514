<template>
  <rq-page-section title="Addresses" collapsible borderless>
    <template #header-actions>
      <ul class="nav">
        <li class="nav-item">
          <b-button
            class="btn btn-link btn-theme"
            automation_id="btn_launch_external_app"
            v-rq-tooltip.hover.top="{ title: `Add Address` }"
            @click="onAddItem"
            :disabled="!hasCompanyAccess || readOnly"
          >
            Add Address
          </b-button>
        </li>
      </ul>
    </template>
    <rqdx-action-data-grid
      ref="dataGrid"
      :automation_id="elementName('tbl')"
      :actions="selectionActions"
      :config="gridConfig"
      :data-source="gridDataSource"
      v-model:search-value="searchText"
      v-model:validationErrors="validationErrors"
      rq-editable
      hide-search
      hide-show-column-chooser
      hide-clear-filters
      @delete="onDeleteItem"
    >
    </rqdx-action-data-grid>
  </rq-page-section>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import DxGridUtils from "@/shared/utilities/DxGridUtils";
import {
  CompanyAddress,
  Company,
} from "@/modules/file/order-entry/contacts/models";
import useGridInvokerMethods from "@/shared/composables/useGridInvokerMethods";
import useGridCompanyAddressAutoCompletePicker from "@/shared/composables/useGridCompanyAddressAutoCompletePicker";
import { useLicenseStore } from "@/store/modules/license";
export default {
  name: "CompanyAddressGrid",
  setup() {
    const { dataGrid, invokeGridMethod, invokeGridComponentMethod } =
      useGridInvokerMethods();

    const { getCompanyAddressAutoCompleteGridColumn } =
      useGridCompanyAddressAutoCompletePicker();

    return {
      dataGrid,
      invokeGridMethod,
      invokeGridComponentMethod,
      getCompanyAddressAutoCompleteGridColumn,
    };
  },
  props: {
    companyAddresses: { type: Array, default: () => new []() },
    info: { type: Object, default: () => ({}) },
    hasCompanyAccess: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      localInfo: {},
      items: [],
      originals: [],
      addressTypes: [],
      counties: [],
      selectedItem: {},
      validationErrors: [],
      addEventName: "",
      validationContext: {},
      searchText: "",
      superMarketDataVerificationEnabled: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.authentication.session.user,
    }),
    ...mapGetters(["lookupHelpers", "lookupItems", "usStatesLookup"]),
    itemKey() {
      return "companyAddressID";
    },
    itemTypeNamePlural() {
      return "Company Addresses";
    },
    itemTypeName() {
      return "Company Address";
    },
    selectionActions() {
      const self = this;
      return [
        {
          name: "delete",
          text: "Delete",
          eventName: "delete",
          allowMultiSelection: true,
          requireSelection: true,
          tooltip: `Delete ${this.itemTypeName}`,
          disabled: function (e) {
            return !self.hasCompanyAccess || self.readOnly;
          },
        },
      ];
    },
  },

  watch: {
    info: {
      handler: function (newValue, oldValue) {
        if (
          !_.isNil(newValue) &&
          (_.isEqual(
            _.toPlainObject(newValue),
            _.toPlainObject(this.localInfo)
          ) ||
            (!_.isNil(this.localInfo) &&
              newValue.companyID == this.localInfo.companyID))
        )
          return;

        this.localInfo = new Company(newValue);
      },
      immediate: true,
      deep: true,
    },
    companyAddresses: {
      handler: function(newValue, oldValue) {
        if(!_.isNil(newValue)) {
          this.originals = _.cloneDeep(this.companyAddresses);
          this.items = _.cloneDeep(this.companyAddresses);
          this.refresh();
        }
      },
      immediate: true,
      deep: true
    },
    validationErrors(newValue, oldValue) {
      const self = this;
      self.$events.emit("update-config-error", {
        message: "Please correct the highlighted errors on screen to continue.",
        hasError: self.validationErrors.length > 0,
      });
    },
  },

  created() {
    const self = this;
    const licenseStore = useLicenseStore();
    const featureFlag = "superMarketAddressAndTaxIdVerification";
    self.superMarketDataVerificationEnabled =
      licenseStore.checkFeature(featureFlag);
    self.addressTypes = self.lookupHelpers.getLookupItems(
      self.lookupItems.ADDRESS_TYPES
    );
    self.counties = self.lookupHelpers.getLookupItems(
      self.lookupItems.COUNTIES
    );
    self.initGridConfig();
    self.initListeners();

    this.originals = _.cloneDeep(this.companyAddresses);
    this.items = _.cloneDeep(this.companyAddresses);
  },

  beforeUnmount() {
    this.$events.off(this.addEventName, this.onAddItem);
  },

  methods: {
    elementName(prefix = "", suffix = "") {
      return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
    },
    initGridConfig() {
      const self = this;
      let uniqueTypeRule = {
        type: "custom",
        message: "The selected address type already exists",
        validationCallback: (params) =>
          !self.checkAddressTypeExists(params.data),
      };

      let gridColumns = [
        {
          dataField: "addressTypeID",
          caption: "Type",
          validationRules: [{ type: "required" }, uniqueTypeRule],
          lookup: {
            dataSource: self.addressTypes,
            displayExpr: "name",
            valueExpr: "id",
          },
        },
        {
          dataField: "state",
          validationRules: [{ type: "required" }],
          lookup: {
            dataSource: self.usStatesLookup,
            displayExpr: "id",
            valueExpr: "id",
          },
          setCellValue(rowData, value) {
            rowData.state = value;
            rowData.countyID = null;
            rowData.county = null;
          },
          editorOptions: { showClearButton: true },
        },
      ];

      if (self.superMarketDataVerificationEnabled) {
        gridColumns.push(
          self.getCompanyAddressAutoCompleteGridColumn({
            column: {
              dataField: "address1",
              caption: "Address 1",
              dataType: "string",
              alignment: "left",
              calculateDisplayValue: "address1",
              validationRules: [{ type: "required" }],
              editorOptions: { maxLength: 100 },
            },
            disabled: self.readOnly || !self.hasCompanyAccess,
          })
        );
      } else {
        gridColumns.push({
          dataField: "address1",
          dataType: "string",
          caption: "Address 1",
          validationRules: [{ type: "required" }],
          editorOptions: { maxLength: 100 },
        });
      }

      gridColumns.push(
        ...[
          {
            dataField: "address2",
            dataType: "string",
            caption: "Address 2",
            editorOptions: { maxLength: 100 },
          },
          {
            dataField: "city",
            dataType: "string",
            caption: "City",
            validationRules: [{ type: "required" }],
            editorOptions: { maxLength: 30 },
          },
          {
            dataField: "zip",
            dataType: "string",
            caption: "Zip",
            validationRules: [{ type: "required" }],
            editorOptions: { maxLength: 10 },
          },
          {
            dataField: "countyID",
            caption: "County",
            lookup: {
              displayExpr: "name",
              valueExpr: "id",
              dataSource(options) {
                return {
                  store: self.counties,
                  filter: options.data
                    ? ["data", "=", options.data.state]
                    : null,
                };
              },
            },
          },
        ]
      );

      if (self.superMarketDataVerificationEnabled) {
        gridColumns.push({
          dataField: "isVerified",
          dataType: "boolean",
          caption: "Verified",
          cellTemplate: DxGridUtils.boolCellTemplate,
        });
      }

      self.gridConfig = {
        sorting: { mode: "single" },
        columns: gridColumns,
        onInitNewRow: (e) => {
          e.data.isVerified = false;
          e.data.companyAddressID = 0;
          e.data.companyID = self.localInfo.companyID;
        },
        onEditorPreparing: (e) => {
          if (e.parentType !== "dataRow") return;
          if (self.readOnly || !self.hasCompanyAccess)
            e.editorOptions.disabled = true;
          if (e.dataField === "isVerified") e.editorOptions.disabled = true;
        },
      };
      self.gridDataSource = {
        key: self.itemKey,
        load() {
          return Promise.resolve(
            _.filter(self.items, (item) => !item.isDeleted)
          );
        },
        insert: self.onGridInsert,
        update: self.onGridUpdate,
      };
    },
    initListeners() {
      this.addEventName = `add:${this.elementName()}`;
      this.$events.on(this.addEventName, this.onAddItem);
    },
    onAddItem() {
      if(_.isNil(this.$refs.dataGrid.gridInstance)) return;
      this.$refs.dataGrid.gridInstance.addRow();
    },
    onDeleteItem(e) {

      if (!e || !e.data) return;
      const self = this;
      let items = e.data;
      let itemLabel =
        items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
      let okHandler = function (args) {
        self.deleteItems(items);
        return true;
      };
      self.$dialog.confirm(
        "Confirm Delete",
        `Are you sure you want to delete the selected ${itemLabel}?`,
        okHandler,
        null,
        { cancelTitle: "No", okTitle: "Yes" }
      );
    },
    onGridInsert(values) {
      const self = this;
      let originalItem = new CompanyAddress();
      let newItem = new CompanyAddress(values);
      newItem.companyAddressID = self.guid();
      newItem.isNew = true;

      let changes = self.getAuditChanges(
        originalItem.toDataObject(),
        newItem.toDataObject()
      );

      let apiPromise = null;

      if (self.superMarketDataVerificationEnabled) {
        apiPromise = self.verifyAddress(newItem).then((verificationResult) => {
          if (verificationResult?.isVerified == true) {
            this.$emit("address-verified-status", true);
            newItem.address1 = verificationResult.address1;
            newItem.address2 = verificationResult.address2;
            newItem.city = verificationResult.city;
            newItem.state = verificationResult.state;
            newItem.zip = verificationResult.zip;
            let counties = self.lookupHelpers.getCountiesByState(newItem.state);
            newItem.countyID = _.find(
              counties,
              (obj) =>
                obj.name.toLowerCase() ===
                verificationResult.county.toLowerCase()
            )?.id;
            newItem.isVerified = true;

            self.items.push(newItem);
            return self.save(newItem, changes);
          } else {
            newItem.isVerified = false;
            self.items.push(newItem);
            self.onAddressNotVerified(
              newItem,
              changes,
              (okResult) => {
                if (okResult && okResult.companyAddressID) {
                  self.refresh();
                  self.$dialog.close();
                }
              },
              () => {
                let itemIndex = _.findIndex(
                  self.items,
                  (item) => item.companyAddressID === newItem.companyAddressID
                );
                if (itemIndex >= 0) self.items.splice(itemIndex, 1);
              }
            );
          }
        });
      } else {
        self.items.push(newItem);
        apiPromise = self.save(newItem, changes);
      }

      return apiPromise
        .then((result) => {})
        .catch((error) => {
          console.log(error);
          if (self.superMarketDataVerificationEnabled) {
            newItem.isVerified = false;
            self.items.push(newItem);
            self.onAddressNotVerified(
              newItem,
              changes,
              (okResult) => {
                if (okResult && okResult.companyAddressID) {
                  self.refresh();
                  self.$dialog.close();
                }
              },
              () => {
                let itemIndex = _.findIndex(
                  self.items,
                  (item) => item.companyAddressID === newItem.companyAddressID
                );
                if (itemIndex >= 0) self.items.splice(itemIndex, 1);
              }
            );
          } else {
            self.$toast.error({ message: `Error saving the company address.` });
          }
        });
    },
    onGridUpdate(key, values) {
      const self = this;
      let itemIndex = _.findIndex(
        self.items,
        (item) => item.companyAddressID === key
      );
      if (itemIndex < 0) return self.onGridInsert(values);
      let originalItem = _.cloneDeep(self.items[itemIndex]);
      let companyAddress = self.items[itemIndex];
      let updatedItem = new CompanyAddress(
        _.assign({}, companyAddress, values)
      );
      let changes = self.getAuditChanges(
        originalItem.toDataObject(),
        updatedItem.toDataObject()
      );
      companyAddress.isUpdated = companyAddress.isNew ? false : true;

      let apiPromise = null;
      if (self.superMarketDataVerificationEnabled) {
        apiPromise = self
          .verifyAddress(updatedItem)
          .then((verificationResult) => {
            if (verificationResult?.isVerified == true) {
              this.$emit("address-verified-status", true);
              updatedItem.address1 = verificationResult.address1;
              updatedItem.address2 = verificationResult.address2;
              updatedItem.city = verificationResult.city;
              updatedItem.state = verificationResult.state;
              updatedItem.zip = verificationResult.zip;
              let counties = self.lookupHelpers.getCountiesByState(
                updatedItem.state
              );
              updatedItem.countyID = _.find(
                counties,
                (obj) =>
                  obj.name.toLowerCase() ===
                  verificationResult.county.toLowerCase()
              )?.id;
              updatedItem.isVerified = true;
              return self.save(updatedItem, changes);
            } else {
              updatedItem.isVerified = false;
              self.onAddressNotVerified(updatedItem, changes, (okResult) => {
                if (okResult && okResult.companyAddressID) {
                  self.$dialog.close();
                }
              });
            }
          });
      } else {
        apiPromise = self.save(updatedItem, changes);
      }

      return apiPromise
        .then((result) => {})
        .catch((error) => {
          console.log(error);
          if (self.superMarketDataVerificationEnabled) {
            updatedItem.isVerified = false;
            self.onAddressNotVerified(updatedItem, changes, (okResult) => {
              if (okResult && okResult.companyAddressID) {
                self.$dialog.close();
              }
            });
          } else {
            self.$toast.error({ message: `Error saving the company address.` });
          }
        });
    },
    verifyAddress(item) {
      const self = this;
      let apiPromise = self.$api.CompanyAddressesApi.verifyCompanyAddress(
        item.toDataObject()
      );
      return self.$rqBusy.wait(apiPromise);
    },
    save(updatedItem, changes) {
      const self = this;
      if (changes.length == 0) {
        return Promise.resolve(updatedItem);
      }
      let targetRowIndex = _.findIndex(
        self.items,
        (item) => item.companyAddressID == updatedItem.companyAddressID
      );

      self.mapChanges(self.items[targetRowIndex], changes);

      let apiPromise = new Promise((resolve) => {
        resolve(self.items[targetRowIndex]);
      });
      return self.$rqBusy
        .wait(apiPromise)
        .then((result) => {
          self.refresh();
          return result;
        })
        .catch((error) => {
          return error;
        });
    },
    mapChanges(item, changes) {
      if (!item) return;
      changes.forEach((change) => {
        item[change.name] = change.new;
      });
    },
    onAddressNotVerified(item, changes, okCallback, cancelCallback) {
      const self = this;
      self.$emit("address-verified-status", false);
      let ok = function () {
        self.$emit("address-verified-status", true);
        return self.save(item, changes).then((result) => {
          okCallback(result);
        });
      };

      let cancel = function () {
        if (!_.isNil(cancelCallback)) {
          cancelCallback();
        }

        self.refresh();
      };

      self.$dialog.confirm(
        "Confirm",
        `The Address Information could not be verified. Do you still want to proceed?`,
        ok,
        cancel,
        { cancelTitle: "No", okTitle: "Yes" }
      );
    },
    deleteItems(items) {
      const self = this;
      if (!_.isArray(items) || _.isEmpty(items)) return;
      _.forEach(items, (delItem) => {
        let itemIndex = _.findIndex(
          self.items,
          (item) => item.companyAddressID === delItem.companyAddressID
        );
        if (itemIndex >= 0) {
          if (delItem.isNew) self.items.splice(itemIndex, 1);
          else self.items[itemIndex].isDeleted = true;
        }
      });
      self.refresh();
    },
    refresh() {
      if(_.isNil(this.$refs.dataGrid.gridInstance)) return;
      this.$refs.dataGrid.gridInstance.clearSelection();
      this.$refs.dataGrid.gridInstance.refresh();
    },
    checkAddressTypeExists(targetItem) {
      const self = this;
      return _.some(
        self.items,
        (item) =>
          item.companyAddressID != targetItem.companyAddressID &&
          item.addressTypeID == targetItem.addressTypeID
      );
    },
    guid() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
  },
};
</script>