import { mapGetters, mapState } from "vuex";
import { SearchRequest } from "@/shared/models/models";
import { RoleType, companyContactRouteNames} from "@/shared/models/enums";
import { DxFilterBuilder } from "@/shared/utilities";
import { watcherPropSync } from "@/shared/utilities/VueHelpers";

export default {
    props: {
        title: { type: String, default: null },
        searchTerm: { type: String, default: null },
        includeRoleTypes: { type: Array, default: () => [] },
        excludeRoleTypes: { type: Array, default: () => [] },
        invisibleColumns: { type: Array, default: () => [] },
        additionalVisibleColumns: { type: Array, default: () => [] },
        additionalOptionalColumns: { type: Array, default: () => [] },
        customActions: { type: Array, default: () => [] },
        regionId: { type: Number, default: 0 },
        selectedKeys: { type: Array, default: () => [] },
        totalCount: { type: Number, default: 0 },
        singleSelect: { type: Boolean, default: false },
        allowAdd: { type: Boolean, default: false },
        allowEdit: { type: Boolean, default: false },
        allowDelete: { type: Boolean, default: false },
        allowActivate: { type: Boolean, default: false },
        showViewContacts: { type: Boolean, default: false },
        showInactive: { type: Boolean, default: false },
        showInactiveColumn: { type: Boolean, default: false },
        persistState: { type: Boolean, default: false },
        persistFilters: { type: Boolean, default: false },
        hideSearch: { type: Boolean, default: false},
        specificRegionOnly: {type: Boolean, default: false},
        isViewingTitleCompany: { type: Boolean, default: false },
    },
    data() {
        return {
            itemKey: "",
            itemName: "",
            itemNamePlural: "",
            hasCompanyAccess: false,
            cannotEditTitleCompanyMessage: 'Title Companies are managed in Configuration',
            searchValue: ""
        };
    },
    computed: {
        ...mapGetters([
            "lookupHelpers",
            "lookupItems",
            "permissionService"
        ]),
        ...mapState({
            globalRegionId: state => state.system.globalRegionId,
            user: state => state.authentication.session.user,
            userAccessibleRegions: state => state.authentication.session.regions,
        }),
        suppressSearch() { return false; },
        baseFilter() { return []; },
        gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
        fixedHeader() { return !_.isEmpty(this.title) || this.allowAdd; },
        allRoles() { return _.filter(this.lookupHelpers.getRoles(), item =>
            !_.includes([RoleType.Bank], item.id)
            && (item.enabled)); },
        roleTypeDataSource() { return _.filter(this.allRoles, item => !_.includes(this.excludeRoleTypes, _.parseInt(item.id))); },
        stateDataSource() { return this.lookupHelpers.getStates(); },
        regionDataSource() { return this.lookupHelpers.getRegions(); },
        companyAccessTooltip() {
            if(!this.hasCompanyAccess)
                return "Access restricted for the current company's region.";
            else if(this.disableAddContactButton)
                return this.cannotEditTitleCompanyMessage;
            return "";
        },
        totalCountValue: {
            get() { return this.totalCount; },
            set(val) { this.$emit("update:totalCount", val); }
        },
        apiCalls() {
            return this.itemName === "company"
                ? { search: "search", delete: "deleteCompanies", activate: "activateCompanies" }
                : { search: "searchContacts", delete: "deleteContacts", activate: "activateContacts" };
        },
        localSecurity(){
            return this.securitySettings.findValues(["AllowEditCompanyDetails","AllowEditContacts", "AllowArchiveUnarchiveAgent", "EditCompaniesOutsideUsersRegion"]);
        },
        allowArchiveUnarchiveAgent() {
            return this.localSecurity.AllowArchiveUnarchiveAgent;
        },
        allowUserAddEditDelete() {
            if(this.itemName === "contact") {
                return this.localSecurity.AllowEditContacts;
            }
            else if(this.itemName === "company") {
                return this.localSecurity.AllowEditCompanyDetails;
            }
        },
        activeOnly() { return !this.allowActivate && !this.showInactive; }
    },
    watch: {
        ...watcherPropSync({
            searchTerm: "searchValue"
        }),
        regionId: {
            handler(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.hasCompanyAccess = this.permissionService.hasCompanyAccess(newValue);
            },
            immediate: true
        },
        selectedKeys(newValue, oldValue) {
            if(newValue === oldValue) return;
            this.selectRows(newValue);
        },
        includeRoleTypes(newValue, oldValue) {
            if(newValue === oldValue) return;
            this.reset();
        },
        excludeRoleTypes(newValue, oldValue) {
            if(newValue === oldValue) return;
            this.reset();
        },
        showInactiveColumn(newValue, oldValue) {
            if(newValue === oldValue) return;
            this.invokeGridMethod("columnOption", "isInactive", "visible", newValue);
            let hasInactiveFilter = this.invokeGridComponentMethod("hasFilter", "isInactive");
            if(hasInactiveFilter)
                this.invokeGridComponentMethod("updateFilter", "isInactive", null);
            else
                this.refresh();
        }
    },
    created() {
        this.initNonObservableData();
        this.initGridConfig();
    },
    methods: {
        checkIsTitleCompanyAndManageCompaniesRoute(selectedItems) {
            let self = this;
            let currentRouteName = _.get(self.$route, "name", "");
            let hasTitleCompany = _.some(selectedItems, x => x.roleTypeID === RoleType.TitleCompany)
            return currentRouteName === companyContactRouteNames.ManageCompanies && hasTitleCompany;
        },
        initNonObservableData() {
            let self = this;
            let actionRestrictedTooltipMessage = "Access Restricted";
            this.gridActions = [];
            if(!_.isEmpty(this.customActions)) {
                this.gridActions = this.customActions.slice();
            }
            if(this.showViewContacts && this.itemName === "company") {
                this.gridActions.push({
                    automation_id: "btn_view_contacts",
                    key: "view-contacts",
                    text: "View Contacts",
                    requireSelection: true,
                    disabled: function(e) {
                        let selectedItem = _.get(e, "data", null) || {};
                        let isTitleCompany = selectedItem.roleTypeID === RoleType.TitleCompany;
                        self.$emit("update:isViewingTitleCompany", isTitleCompany);

                        return false;
                    }
                });
            }
            if(this.allowEdit){
                this.gridActions.push({
                    automation_id: `btn_edit_${this.itemName}`,
                    key: "edit-item",
                    text: "Edit",
                    disableWhenReadOnly: true,
                    requireSelection: true,
                    disabled: function(e) {
                        let selectedItem = _.get(e, "data", null) || {};
                        let editAdminUserRegion = self.localSecurity.EditCompaniesOutsideUsersRegion && selectedItem.regionID === self.globalRegionId;
                        if(!self.allowUserAddEditDelete || (!editAdminUserRegion && !self.hasRegionAccess([selectedItem]))) {
                            return actionRestrictedTooltipMessage;
                        }

                        if(self.checkIsTitleCompanyAndManageCompaniesRoute([selectedItem]))
                            return self.cannotEditTitleCompanyMessage;

                        return false;
                    }
                });
            }
            if(this.allowDelete) {
                let self = this;

                this.gridActions.push({
                    automation_id: `btn_delete_${this.itemName}`,
                    key: "delete-item",
                    name: "delete",
                    text: "Delete",
                    disableWhenReadOnly: true,
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        let selectedItems = _.get(e, "data", null) || [];
                        if(!self.allowUserAddEditDelete || !self.hasRegionAccess(selectedItems)) {
                            return actionRestrictedTooltipMessage;
                        }

                        if(self.checkIsTitleCompanyAndManageCompaniesRoute(selectedItems))
                            return self.cannotEditTitleCompanyMessage;

                        return false;
                    }
                });
            }
            if(this.allowActivate) {
                this.gridActions.push({
                    automation_id: `btn_activate_${this.itemName}`,
                    name: "activate",
                    text: "Activate",
                    key: "activate-item",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        let selectedItems = _.get(e, "data", null) || [];
                        if(!self.allowUserAddEditDelete || !self.hasRegionAccess(selectedItems)) {
                            return actionRestrictedTooltipMessage;
                        }

                        if(self.checkIsTitleCompanyAndManageCompaniesRoute(selectedItems))
                            return self.cannotEditTitleCompanyMessage;

                        return _.some(e.data, ["isInactive", false]);
                    }
                });
                this.gridActions.push({
                    automation_id: `btn_inactivate_${this.itemName}`,
                    name: "inactivate",
                    text: "Inactivate",
                    key: "inactivate-item",
                    requireSelection: true,
                    allowMultiSelection: true,
                    disabled: function(e) {
                        let selectedItems = _.get(e, "data", null) || [];
                        if(!self.allowUserAddEditDelete || !self.hasRegionAccess(selectedItems)) {
                            return actionRestrictedTooltipMessage;
                        }

                        if(!self.allowArchiveUnarchiveAgent)
                            return actionRestrictedTooltipMessage;

                        if(self.checkIsTitleCompanyAndManageCompaniesRoute(selectedItems))
                            return self.cannotEditTitleCompanyMessage;

                        return _.some(e.data, ["isInactive", true]);
                    }
                });
            }
        },

        hasRegionAccess(selectedItems) {
            const self = this;

            // These all do the same thing, just in various "ways".
            //return _.every(selectedItems, (selectedItem) => _.some(self.userAccessibleRegions, { regionID: selectedItem.regionID } ));
            //return _.listToListExist(_.every, selectedItems, self.userAccessibleRegions, (left, right) => left.regionID == right.regionID);
            //return _.listToListExist(_.every, selectedItems, self.userAccessibleRegions, (left) => ({ regionID: left.regionID }));
            return _.listEveryExists(selectedItems, self.userAccessibleRegions, (sel) => ({ regionID: sel.regionID }));
        },

        initGridConfig(){
            const self = this;
            let keys = self.validKeys(self.selectedKeys);
            self.gridConfig = {
                columns: self.getGridColumns(),
                focusedRowEnabled: true,
                noDataText: `No ${self.itemName} available.`,
                selection: { mode: "multiple", allowSelectAll: false },
                selectedRowKeys: keys.length > 0 ? keys : null,
                focusedRowKey: keys.length > 0 ? keys[0] : null,
                remoteOperations: { paging: true, sorting: true, filtering: true },
                scrolling: {useNative:true},
                onSelectionChanged: self.onGridSelectionChanged,
                onRowDblClick: self.onGridDoubleClick
            };

            self.gridDataSource = {
                key: self.itemKey,
                load: self.fetchData
            };
        },

        onGridAction(e) {
            const self = this;
            if(e.action.key != "view-contacts"){
                let selectedItems = _.get(e, "data", null) || [];

                if(!Array.isArray(selectedItems) ){
                    selectedItems = [selectedItems];
                }

                let editAdminUserRegion = self.localSecurity.EditCompaniesOutsideUsersRegion && _.some(selectedItems, item => item.regionID === self.globalRegionId);

                if(!self.allowUserAddEditDelete || (!editAdminUserRegion && !self.hasRegionAccess(selectedItems)) || self.checkIsTitleCompanyAndManageCompaniesRoute(selectedItems)){
                    return;
                }

                if(e.action.key == "inactivate-item" && !self.allowArchiveUnarchiveAgent){
                    return;
                }
            }

            switch(e.action.key) {
                case "edit-item":
                    self.$emit("edit", e);
                    return;
                case "delete-item":
                    self.confirmBatchAction(e, "deleteItems");
                    return;
                case "activate-item":
                case "inactivate-item":
                    self.confirmBatchAction(e, "toggleActivateStatus");
                    return;
                case "view-contacts":
                    self.$emit("view-contacts", e);
                    return;
            }
            self.$emit("custom-action", e);
        },

        onGridSelectionChanged(e) {
            if (e.selectedRowKeys.length == 0) e.component.option("focusedRowIndex", -1);
            this.$emit("update:selectedKeys", e.selectedRowKeys);
        },

        onGridDoubleClick(e){
            if(this.checkIsTitleCompanyAndManageCompaniesRoute([e.data])) return;
            this.$emit("row-double-click", { name: this.itemName, data: e.data });
        },

        onGridReset(e) {
            this.$emit("reset");
        },

        getSearchRequest(loadOptions) {
            const self = this;
            let request = SearchRequest.fromLoadOptions(loadOptions);
            request.searchTerm = self.suppressSearch ? "" : self.searchValue;
            request.parameters = {
                regionId: self.regionId,
                activeOnly: self.activeOnly,
                specificRegionOnly: self.specificRegionOnly,
            };
            request.pagingEnabled = true;
            request.filter = self.getRequestFilter(loadOptions);
            return request;
        },

        getRequestFilter(loadOptions) {
            const self = this;
            let filterBuilder = new DxFilterBuilder(loadOptions);

            if(!_.isEmpty(self.baseFilter))
                filterBuilder.append(self.baseFilter);

            if (!self.showInactiveColumn)
                filterBuilder.append(["IsInactive", "=", false]);

            if(!_.isEmpty(self.excludeRoleTypes))
                filterBuilder.append(["RoleTypeID","NOTIN",self.excludeRoleTypes.slice(), ["ISNULL",0]]);
            if(_.includes(self.excludeRoleTypes, RoleType.TitleCompany)) {//RQO-31149 - we want to add back TCs that are also POCs
                let subFilter = [["RoleTypeID","=",RoleType.TitleCompany], "and", ["IsPlaceOfClosing","=",true]];
                if (!self.showInactiveColumn) subFilter.push("and", ["IsInactive", "=", false]);
                filterBuilder.append(subFilter, "OR", true);
            }

            let validRoleTypes = _.filter(self.includeRoleTypes, rt => !_.isNil(rt) && _.gte(rt,0))
            if(!_.isEmpty(validRoleTypes)){
                if ( _.findIndex(validRoleTypes, i => { return i === RoleType.PlaceOfClosing}) > -1 ) {
                    filterBuilder.append([["RoleTypeID","IN",validRoleTypes.slice()], "or",["IsPlaceOfClosing","=",true]]);
                }
                else if( _.findIndex(validRoleTypes, i => { return i === RoleType.SettlementAgent}) > -1 ) {
                    filterBuilder.append([["RoleTypeID","IN",validRoleTypes.slice()], "or",["IsSettlementAgent","=",true]]);
                }
                else{
                    filterBuilder.append(["RoleTypeID","IN",validRoleTypes.slice()]);
                }
            }

            let roleTypeFilter = filterBuilder.exprValueByKey('roleTypeID');
            let hasSettlementAgentFilter = _.some(roleTypeFilter, x => _.get(x, "[2]", 0) === RoleType.SettlementAgent);
            let hasPlaceOfClosingFilter = _.some(roleTypeFilter, x => _.get(x, "[2]", 0) === RoleType.PlaceOfClosing);
            if(hasSettlementAgentFilter){
                let subFilter2 = [["RoleTypeID","=",RoleType.TitleCompany], "and", ["IsSettlementAgent","=",true]]
                if (!self.showInactiveColumn) subFilter2.push("and", ["IsInactive", "=", false]);
                filterBuilder.append(subFilter2, "OR", true);
            }
            if(hasPlaceOfClosingFilter){
                let subFilter3 = [["RoleTypeID","=",RoleType.TitleCompany], "and", ["IsPlaceOfClosing","=",true]];
                if (!self.showInactiveColumn) subFilter3.push("and", ["IsInactive", "=", false]);
                filterBuilder.append(subFilter3, "OR", true);
            }

            return filterBuilder.filterExpression;
        },

        fetchData(loadOptions) {
            const self = this;
            if(self.cancelFetchData(loadOptions)) return;
            let emptyResult = { data: [], totalCount: 0 };
            let request = self.getSearchRequest(loadOptions);
            if(self.includeRoleTypes.includes(RoleType.Bank))
                request.parameters["includeEscrowAccounts"] = true;
            let apiPromise = _.invoke(self.$api.CompaniesApi, self.apiCalls.search, request);
            return self.$rqBusy.wait(apiPromise)
                .then(response => {
                    self.totalCountValue = response.totalRecords;
                    return { data: response.results, totalCount: response.totalRecords };
                })
                .catch(err => {
                    console.error(err);
                    return emptyResult;
                });
        },

        //making this a method instead of a computed since this is an optional column
        getCounties() {
            return this.lookupHelpers.getLookupItems(this.lookupItems.COUNTIES);
        },

        getAdditionalGridColumns(columns) {
            const self = this;
            if(_.isEmpty(self.additionalVisibleColumns) && _.isEmpty(self.additionalOptionalColumns)) return [];
            let additionalColumns = _.filter(columns, c => _.includes(self.additionalVisibleColumns, c.dataField) || _.includes(self.additionalOptionalColumns, c.dataField));
            _.forEach(additionalColumns, c => {
                if(!_.includes(self.additionalOptionalColumns, c.dataField)) return;
                c.visible = false;
            });
            return additionalColumns;
        },

        toggleActivateStatus(keys, verb) {
            const self = this;
            if(_.isEmpty(keys)) return Promise.resolve(true);
            let apiPromise = _.invoke(self.$api.CompaniesApi, self.apiCalls.activate, keys);
            self.$rqBusy.wait(apiPromise)
                .then(() => {
                    self.reset();
                    self.toastSuccess(verb, keys.length);
                    return true;
                })
                .catch(error => {
                    self.toastFailure(verb, keys.length);
                    console.error(error);
                    return error;
                });
        },

        deleteItems(keys) {
            const self = this;

            if(_.isEmpty(keys)) return Promise.resolve(true);
            var displayText = "";
            if(self.apiCalls.delete.includes("Companies"))
                displayText = (keys.length > 1) ? "Companies are":"Company is";
            else
                displayText = (keys.length > 1) ? "Contacts are":"Contact is";
            let apiPromise = _.invoke(self.$api.CompaniesApi, self.apiCalls.delete, keys);
            return self.$rqBusy.wait(apiPromise)
                .then(response => {
                    if(response === false){
                        self.$toast.warn(`${displayText} in use in a file and cannot be deleted.`);
                    }
                    else {
                        self.toastSuccess("delete", keys.length);
                    }
                    self.refresh();
                    return true;
                })
                .catch(e => {
                    self.$toast.error(e.errorMessage);
                    return e;
                });
        },

        confirmBatchAction(e, method) {
            let items = _.get(e, "data", []) || [];
            if(_.isEmpty(items)) return;
            const self = this;
            let actionName = e.action.name;
            let okHandler = function () {
                let keys = _.map(items, self.itemKey);
                self[method](keys, actionName);
                return true;
            }
            self.$dialog.confirm(
                `Confirm ${_.startCase(actionName)}`,
                `Are you sure you want to ${actionName} the selected ${items.length > 1 ? self.itemNamePlural : self.itemName}?`,
                okHandler,
                null, { cancelTitle: "No", okTitle: "Yes"});
        },

        toastSuccess(verb, itemCount) {
            let usePlural = itemCount > 1;
            let message = `Selected ${usePlural ? this.itemNamePlural : this.itemName} ${usePlural ? "were" : "was"} ${verb}d successfully.`;
            this.$toast.success(message);
        },

        toastFailure(verb, itemCount, reason=null) {
            let usePlural = itemCount > 1;
            let reasonPart = _.isEmpty(reason) ? "." : ` ${reason}.`;
            let message = `Selected ${usePlural ? this.itemNamePlural : this.itemName} failed to be ${verb}d`;
            this.$toast.error(message + reasonPart);
        },

        validKeys(keys) { return !_.isNil(keys) && _.isArray(keys) ? _.filter(keys, k => _.gt(k,0)) : []; },

        cancelFetchData(loadOptions) {
           return _.isEmpty(loadOptions)
               || (!_.parseBool(loadOptions.requireTotalCount)
                    && (_.get(loadOptions, "filter[0]", null) === this.itemKey
                        || _.get(loadOptions, "filter[0][0]", null) === this.itemKey));
        },

        getSelectedData() { return this.invokeGridMethod("getSelectedRowsData"); },

        updateDimensions() { this.invokeGridMethod("updateDimensions"); },

        clearSelection() { this.invokeGridMethod("clearSelection"); },

        selectRows(keys, scrollIntoView=true) {
            this.invokeGridMethod("selectRows", keys);
            //if(!scrollIntoView || _.isEmpty(keys)) return;
            //https://supportcenter.devexpress.com/ticket/details/t501746/datagrid-how-to-scroll-to-the-selected-row-on-another-page
            //using focusedRowKey instead
            // let index = this.invokeGridMethod("getRowIndexByKey", [keys[0]]);
            // let rowElement = this.invokeGridMethod("getRowElement", [index]);
            // this.invokeGridMethod("getScrollable").scrollToElement(rowElement);
        },

        refresh() { return this.invokeGridMethod("refresh"); },

        reset() {
            this.clearSelection();
            return this.refresh();
        },

        invokeGridMethod(method, ...params) {
            return this.invokeGridComponentMethod("invokeGridMethod", method, ...params);
        },

        invokeGridComponentMethod(method, ...params) {
            return _.invoke(this, `$refs.dataGrid.${method}`, ...params);
        }
    }
}