<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="tbl_companies"
        :title="title"
        title-size="sm"
        :actions="gridActions"
        :data-source="gridDataSource"
        :config="gridConfig"
        :persist-state="persistState"
        :persist-filters="persistFilters"
        :strikethrough-if-true="['isInactive']"
        :prevent-persist-columns="['isInactive']"
        :fixed-header="fixedHeader"
        :hide-search="hideSearch"
        :single-selection-enabled="singleSelect"
        :force-floating-header="!hasTitle"
        v-model:search-value="searchValue"
        @action="onGridAction"
        @reset="onGridReset"
        integrated-search
        remote-search
        rq-filters>
        <template #toolbar>
            <ul v-if="allowAdd && localSecurity.AllowEditCompanyDetails" class="nav navbar-nav">
                <li class="nav-item">
                    <b-btn
                        automation_id="btn_add_company"
                        variant="theme"
                        size="sm"
                        @click="$emit('add')">Add
                    </b-btn>
                </li>
                <li class="nav-item">
                    <div 
                        v-rq-tooltip.hover="{title: 'When filtering Settlement Agents or Place of Closing results include role and check box column results'}">
                        <FontAwesomeIcon icon="fa-solid fa-circle-info"/>
                    </div>
                </li>
            </ul>
            <slot name="toolbar">
                <div class="me-auto"></div>
            </slot>
        </template>
    </rqdx-action-data-grid>
</template>
<script>
    import { DxGridUtils } from "@/shared/utilities";
    import CompanyContactGridMixin from "./CompanyContactGridMixin";
    export default {
        name: "CompanyGrid",
        mixins: [CompanyContactGridMixin],
        data() {
            return {
                itemKey: "companyID",
                itemName: "company",
                itemNamePlural: "companies",
            };
        },
        computed: {
            hasTitle() { return !_.isEmpty(this.title); },
            //localSecurity(){
            //    return this.securitySettings.findValues(["AllowEditCompanyDetails"]);
            //},
        },
        methods: {
            getGridColumns() {
                const self = this;
                let columns = [
                    {
                        dataField: "name",
                        dataType: "string",
                        caption: "Company Name",
                        sortIndex: 0,
                        sortOrder: "asc"
                    },
                    {
                        dataField: "roleTypeID",
                        caption: "Role",
                        alignment: "left",
                        calculateDisplayValue: "role",
                        dataType: "number",
                        rqFilter: {
                            displayExpr: "name",
                            valueExpr: "id",
                            dataSource: self.roleTypeDataSource
                        }
                    },
                    { dataField: "companyID", dataType: "number" },
                    { dataField: "address1", caption: "Address", dataType: "string" },
                    { dataField: "city", caption: "City", dataType: "string" },
                    {
                        dataField: "state",
                        caption: "State",
                        dataType: "string",
                        lookup: {
                            displayExpr: "id",
                            valueExpr: "id",
                            dataSource: self.stateDataSource
                        }
                    },
                    { dataField: "zip", dataType: "string" },
                    { dataField: "eMailAddress", caption: "Email", dataType: "string" },
                    { dataField: "phone", dataType: "string" },
                ];
                if(self.showInactive || self.allowActivate) {
                    columns.push({
                        dataField: "isInactive",
                        caption: "Inactive",
                        dataType: "boolean",
                        cellTemplate: DxGridUtils.boolCellTemplate,
                        visible: self.showInactiveColumn
                    });
                }
                let optionalColumns = [
                    {
                        visibleIndex: 0,
                        dataField: "regionID",
                        caption: "Region",
                        dataType: "number",
                        calculateDisplayValue: "regionDisplay",
                        calculateSortValue: "regID",
                        alignment: "left",
                        rqFilter: {
                            dataSource: self.regionDataSource,
                            displayExpr: "description",
                            valueExpr: "regionID"
                        }
                    },
                    { dataField: "friendlyName", dataType: "string" },
                    { dataField: "address2", dataType: "string" },
                    {
                        dataField: "countyID",
                        caption: "County",
                        dataType: "number",
                        calculateDisplayValue: "countyName",
                        calculateSortValue: "countyName",
                        alignment: "left",
                        rqFilter: {
                            dataSource: {
                                loadMode: "raw",
                                load() { return self.getCounties(); }
                            }
                        }
                    },
                    { dataField: "webSite", caption: "Website", dataType: "string" },
                    { dataField: "notes", caption: "Note", dataType: "string" },
                    { dataField: "fax", dataType: "string" },
                    { dataField: "accountReportCode", dataType: "string" },
                    { dataField: "tollFreeNumber", dataType: "string" },
                    { dataField: "tollFreeFax", dataType: "string" },
                    { dataField: "businessAddressLocation", dataType: "string" },
                    { dataField: "mailingAddress", dataType: "string" },
                    { dataField: "stateLicenseID", dataType: "string" },
                    { dataField: "stateIssuedLicense" , dataType: "string"},
                    { dataField: "altaUniversalId", dataType: "string" },
                    { dataField: "isArchived", caption: "Archived", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    { dataField: "isServiceProvider", caption: "Archived", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    { dataField: "isPlaceOfClosing", caption: "Place of Closing", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    { dataField: "isSettlementAgent", caption: "Settlement Agent", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    { dataField: "isPreferred", caption: "Preferred", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate }
                ];

                let additionalColumns = self.getAdditionalGridColumns(optionalColumns);
                if(!_.isEmpty(additionalColumns))
                    columns.push(...additionalColumns);

                //add custom search highlight to searcheable columns
                const searcheableColumns = [
                    "friendlyName",
                    "name",
                    "address1",
                    "address2",
                    "city",
                    "zip",
                    "state",
                    "notes",
                    "phone",
                    "fax",
                    "eMailAddress",
                    "accountReportCode",
                    "tollFreeNumber",
                    "tollFreeFax",
                    "businessAddressLocation",
                    "mailingAddress",
                    "nMLSID",
                    "stateLicenseID",
                    "stateIssuedLicense",
                    "companyID"
                ];

                _.forEach(columns, c => {
                    if(_.includes(self.invisibleColumns, c.dataField)){
                        c.visible = false;
                    }
                    if(_.includes(searcheableColumns, c.dataField) && _.isNil(c.cellTemplate)) {
                        c.rqOptions = {
                            highlightSearchText: true,
                            truncateCellText: c.dataField === "notes"
                        };
                    }
                });

                return columns;
            }
        }
    }
</script>
