<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :config="gridConfig"
        :data-source="gridDataSource"
        :actions="selectionActions"
        v-model:validation-errors="validationErrors"
        @delete="onDeleteItems"
        rq-filters
        rq-editable
        integrated-search
    >
        <template #toolbar>
            <ul class="nav me-auto">
                <li class="nav-item">
                    <b-btn 
                        variant="theme" 
                        @click="onAddItem"
                        :disabled="readOnly"
                        >Add
                    </b-btn>
                </li>
            </ul>
        </template>
    </rqdx-action-data-grid>
</template>

<script>
    import { UnderwriterPremiumSplitDto } from '@order-entry/contacts/models';
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    const NO_COUNTY_ASSIGNED = null;
    const ALL_COUNTIES_ID = -1;
    const ALL_OTHER_COUNTIES_ID = -999;
    const nonCounties = [ NO_COUNTY_ASSIGNED, ALL_COUNTIES_ID, ALL_OTHER_COUNTIES_ID ];

    export default {
        name: "UnderwriterPremiumSplitIds",
        props: {
            underwriterPremiumSplits: { type: Array, default: ()=> new [] },
            readOnly: { type: Boolean, default: false },
        },
        data() {
            return  {
                validationErrors: [],
                addEventName: "",
                showBanner: false,
                items: [],
                originals: []
            }
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            selectionActions() {
                const self = this;
                return [{
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    requireSelection: true,
                    allowMultiSelection: true,
                    tooltip: "Delete Category",
                    disabled: function(e) {
                        return self.readOnly;
                    }
                }];
            },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || "underwriterPremiumSplitID"; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            states() { return this.lookupHelpers.getStatesWithID() },
            counties() { return this.getAllCounties(); },
            isValid() { return this.validationErrors.length == 0; }
        },

        watch: {
            items: {
                handler(val, oldVal) {
                    const self = this;
                    self.refreshCountiesID();
                },
                deep: true
            },
            validationErrors: {
                handler(val, oldVal){
                    const self = this;
                    self.$emit('validation-event', self);
                }
            },
        },

        created() {
            const self = this;
            this.originals = _.cloneDeep(self.underwriterPremiumSplits);
            this.items = _.cloneDeep(self.underwriterPremiumSplits);
            self.setCounties();
            self.refreshCountiesID();
            self.initGridConfig();
        },

        beforeUnmount() {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            getFilter(options) {
                const self = this;
                let results = [];
                if (!options.data) return null;
                const stateID = options.data.stateID;
                const stateCode = self.states.find(i => i.id == stateID)?.data;
                results.push(['stateCode', '=', stateCode]);
                if (self.stateInItems(options.data.stateID)) {
                    results.push(['id', '<>', ALL_COUNTIES_ID]);
                } else {
                    results.push(['id', '<>', ALL_OTHER_COUNTIES_ID]);
                }
                return results;
            },

            refreshCountiesID() {
                const self = this;
                self.items.forEach(i =>{
                    if (!i.isDeleted && _.parseNumber(i.countyID, 0) <= 0) {
                        i.countyID = self.stateHasMultipleEntries(i) ? ALL_OTHER_COUNTIES_ID : ALL_COUNTIES_ID;
                    }
                });
            },

            stateHasMultipleEntries(item){
                const self = this;
                let results = _.find(self.items, i => 
                       i.underwriterPremiumSplitID != item.underwriterPremiumSplitID
                    && i.stateID == item.stateID
                    && !i.isDeleted
                    && self.hasAssignedCounty(i.countyID)
                );
                return !!results;
            },

            addCountyFiltering(stateID, filterArray) {
                const self = this;
                if (!stateID) return;
                let items = _.filter(self.items, i => i.stateID == stateID && !i.isDeleted);
                let countiesToFilterOut = _.map(items, i=> ({ countyID: i.countyID }));
                countiesToFilterOut.forEach(f => {
                    filterArray.push(['id', '<>', f.countyID ]);
                });
            },
            stateInItems(stateID) {
                const self = this;
                return !!_.find(self.items, i=>i.stateID == stateID && _.parseNumber(i.countyID, 0) > 0 && !i.isDeleted);
            },
            initGridConfig() {
                const self = this;
                let decimalValidation = {
                    type: "custom",
                    message: "Must be numeric.",
                    validationCallback: e => _.parseNumber(e.data.percentage)
                };
                let rowValidation = {
                    type: "custom",
                    message: "State/County already exists",
                    validationCallback: params => self.doRowValidation(params.data),
                    reevaluate: true
                };
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "stateID",
                            caption: "State",
                            width: 150,
                            minWidth: 150,
                            alignment: "left",
                            setCellValue(rowData, value) {
                                rowData.stateID = value;
                                rowData.countyID = null;
                                self._currentStateID = value;
                            },
                            lookup: {
                                dataSource: self.states,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [ { type: "required" }, rowValidation ],
                        },
                        {
                            dataField: "countyID",
                            caption: "County",
                            width: 500,
                            minWidth: 500,
                            alignment: "left",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource(options) {
                                    return {
                                        store: self.counties,
                                        filter: self.getFilter(options)
                                    }
                                }
                            },
                            validationRules: [ { type: "required" }, rowValidation ]
                        },
                        {
                            dataField: "percentage",
                            datatype: "number",
                            caption: "Percentage",
                            cellTemplate: DxGridUtils.rateCellTemplate,
                            format: {
                                type: "percent",
                                precision: 2
                            },
                            editorOptions: { format: "##0.##'%'", max: 100, min: 0 },
                            validationRules: [
                                { type: "required" },
                                { type: "range", min: 0, max: 100, message: "Invalid percentage range."},
                                rowValidation,
                                decimalValidation
                            ]
                        }
                    ],
                    onInitNewRow(e) {
                        if (self.isExistingRecord) {
                            self.isExistingRecord = false;
                            return false;
                        }
                        e.data.underwriterPremiumSplitID = null;
                        e.data.isDefault = false;
                    },
                    onEditorPreparing: (e) => {
                        if (e.parentType !== "dataRow") return;
                        e.editorOptions.disabled = !e.row.isNewRow;
                        if (self.readOnly) e.editorOptions.disabled = true;
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load() {
                        return Promise.resolve(_.filter(self.items, item => !item.isDeleted));
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            onGridInsert(values) {
                const self = this;
                let originalItem = new UnderwriterPremiumSplitDto();
                let newItem = new UnderwriterPremiumSplitDto(values);
                newItem.underwriterPremiumSplitID = self.guid();
                newItem.isNew = true;

                let changes = self.getAuditChanges(
                    originalItem.toDataObject(),
                    newItem.toDataObject()
                );
                if (self.isDuplicate(newItem)) return false;
                if (!newItem.percentage) return false;
                self.items.push(newItem);
                self.checkForCountyEntryRemoval(newItem);
                return self.save(newItem, changes).then((result) => {
                });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.underwriterPremiumSplitID == key);
                if (itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let underwriterPremiumSplit = self.items[itemIndex];
                let updatedItem = new UnderwriterPremiumSplitDto(
                    _.assign({}, underwriterPremiumSplit, values)
                );
                let changes = self.getAuditChanges(
                    originalItem.toDataObject(),
                    updatedItem.toDataObject()
                );
                underwriterPremiumSplit.isUpdated = true;
                if (self.isDuplicate(updatedItem)) return false;
                self.checkForCountyEntryRemoval(originalItem);
                self.save(updatedItem, changes).then((result) => {});
            },

            onAddItem() {
                const self = this;
                if (!self.gridInstance) return;
                self.gridInstance.addRow();
            },

            onDeleteItems(e) {
                const self = this;
                self.$dialog.confirm("Confirm", "Are you sure you want to delete the selected Underwriter Premium Splits for this section?", () => self.deleteItems(e.data));
            },

            deleteItems(items) {
                const self = this;
                if (!_.isArray(items) || _.isEmpty(items)) return;
                _.forEach(items, delItem => {
                    let itemIndex = _.findIndex(self.items, item => item.underwriterPremiumSplitID === delItem.underwriterPremiumSplitID);
                    if (itemIndex >= 0 && delItem.underwriterPremiumSplitID <= 0)
                        self.items.splice(itemIndex, 1);
                    else
                        self.items[itemIndex].isDeleted = true;
                });
                self.refresh();
            },

            hasAssignedCounty (countyID) { return !nonCounties.includes(countyID) },
            checkForCountyEntryRemoval(item) {
                const self = this;
                const itemHasAssignedCounty = this.hasAssignedCounty(item.countyID);
                if (!self.stateHasCounties(item.stateID)) return;
                if (itemHasAssignedCounty) return;
                _.map(self.items, i => {
                    if (i.underwriterPremiumSplitID != item.underwriterPremiumSplitID
                        && i.stateID == item.stateID
                        && self.hasAssignedCounty(i.countyID)
                    ) {
                        switch (nonCounties[item.countyID]) {
                            case ALL_OTHER_COUNTIES_ID:
                                i.isDeleted = false;
                                break;
                            case ALL_COUNTIES_ID:
                                i.isDeleted = true;
                                break;
                            default:
                                break;
                        }
                    }
                });
            },

            isDuplicate(item) {
                const self = this;
                let isDupe = _.find(self.items, i => {
                    return i.stateID == item.stateID
                        && i.countyID == item.countyID
                        && i.underwriterPremiumSplitID != item.underwriterPremiumSplitID
                        && !i.isDeleted
                });
                return !!isDupe;
            },

            stateHasCounties(stateID) {
                const self = this;
                const stateCode =  _.find(self.states, state => state.id == stateID).data;
                const hasCounties = _.find(self.counties, county => county.stateCode == stateCode);
                return !!hasCounties;
            },

            save(updatedItem, changes) {
                const self = this;
                if (changes.length == 0) {
                    return Promise.resolve(updatedItem);
                }
                let targetRowIndex = _.findIndex(self.items, item=>item.underwriterPremiumSplitID == updatedItem.underwriterPremiumSplitID);

                self.mapChanges(self.items[targetRowIndex], changes);

                let apiPromise = new Promise((resolve)=>{resolve(self.items[targetRowIndex])});
                return self.$rqBusy
                    .wait(apiPromise)
                    .then((result) => {
                        self.refresh();
                        return result;
                    })
                    .catch((error) => {
                        return error;
                    });
            },

            mapChanges(item, changes) {
                changes.forEach(change => {
                   item[change.name] = change.new;
                });
            },

            refresh() {
                if (_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            guid() {
                return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            },

            getAllCounties() {
                const self = this;
                return self._counties;
            },

            doRowValidation(targetItem) {
                const self = this;
                let isNewId = !!(targetItem.underwriterPremiumSplitID <= 0 || _.isNumber(targetItem.underwriterPremiumSplitID) || !targetItem.underwriterPremiumSplitID);
                self.rowValidationMessage = "";
                let isValid =  (isNewId || (!!targetItem.stateID && !!targetItem.percentage))
                                && !self.isDuplicate(targetItem);
                return isValid;
            },

            setCounties() {
                const self = this;
                self._counties = [];
                const states = _.map(self.lookupHelpers.getStates(), i => i.id);
                const counties = self.lookupHelpers.getCountiesInStates(states);
                const allCounties = _.map(states, state => ({ id: ALL_COUNTIES_ID, name: '-- All Counties --', stateCode: state }));
                const allOtherCounties = _.map(states, state => ({ id: ALL_OTHER_COUNTIES_ID, name: '-- All Other Counties --', stateCode: state }));
                const results = _.map(counties, d => ({ id: _.parseNumber(d.id, null), name: d.name, stateCode: d.data }));
                results.unshift(...allCounties);
                results.unshift(...allOtherCounties);
                self._counties = results;
                self._filteredCounties = [];
            }
        }
    }
</script>