<template>
    <div class="contact-info">
        <fieldset :disabled="!hasCompanyAccess || readOnly">
            <div class="row">
                <div class="col-3 form-group">
                    <label for="txt_contact_id">ID:</label>
                    <input id="txt_contact_id"
                        automation_id="txt_contact_id"
                        type="text"
                        class="form-control"
                        placeholder="Contact ID"
                        title="Contact ID cannot be modified"
                        v-model="localContact.contactID"
                        readonly
                    />
                </div>
                <div class="col-3 form-group" :class="{'form-required':true, 'has-error':v$.localContact.firstName.$error}">
                    <label for="txt_contact_first">First Name</label>
                    <input id="txt_contact_first"
                        automation_id="txt_contact_first"
                        type="text"
                        class="form-control"
                        placeholder="First Name"
                        v-model="v$.localContact.firstName.$model"/>
                </div>

                <div class="col-3 form-group" :class="{'form-required':true, 'has-error':v$.localContact.lastName.$error}">
                    <label for="txt_contact_last">Last Name</label>
                    <input id="txt_contact_last"
                        automation_id="txt_contact_last"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                        v-model="v$.localContact.lastName.$model"/>
                </div>

                <div class="col-3 form-group">
                    <label for="txt_contact_title">Title</label>
                    <input id="txt_contact_title" automation_id="txt_contact_title" type="text" class="form-control" placeholder="Title" v-model="localContact.jobTitle">
                </div>

                <div class="col-3 form-group">
                    <label for="txt_contact_email">Email</label>
                    <input id="txt_contact_email" automation_id="txt_contact_email" type="text" class="form-control" placeholder="Email" v-model="localContact.emailAddress">
                </div>
                
                <div class="col-3 form-group">
                    <div class="row">
                        <label class="col col-form-label" for="txt_contact_address1">Address 1</label>
                        <div class="col-auto form-group-action ms-auto">
                            <button automation_id="btn_copy_company_address" type="button" class="btn btn-link btn-theme" @click="copyCompanyAddress">Use Company Address</button>
                        </div>
                    </div>
                    <dx-autocomplete
                            ref="addressContactAutocomplete"
                            class="form-control"
                            id="txt_contact_address1" 
                            automation_id="txt_contact_address1"
                            :dataSource="addressDataSource"
                            item-template="item-template"
                            :min-search-length="1"
                            :input-attr="addressInputAttr"
                            :disabled="false"
                            value-expr="text"
                            v-model="localContact.address1"
                            @initialized="onAddressContentReady"
                            @focus-out="onAddress1FocusOut"
                            @focus-in="onAddress1FocusIn"
                            @opened="onAddress1Opened"
                            @selectionChanged="onAutoCompleteSelectionChanged"
                            @value-changed="onAutoCompleteValueChanged"
                        >
                            <template #item-template="{ data }">
                                <div>{{data ? data.text : ""}}</div>
                            </template>
                        </dx-autocomplete>
                </div>

                <div class="col-3 form-group">
                    <label for="txt_contact_address2">Address 2</label>
                    <input id="txt_contact_address2" automation_id="txt_contact_address2" type="text" class="form-control" placeholder="Line 2" v-model="localContact.address2">
                </div>

                <div class="col-3 form-group">
                    <label for="txt_contact_city">City</label>
                    <input id="txt_contact_city" automation_id="txt_contact_city" type="text" class="form-control" placeholder="City" v-model="localContact.city">
                </div>

                <div class="col-3 form-group">
                    <label for="drp_contact_state">State</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_contact_state', id: 'drp_contact_state' }"
                        :items="usStatesLookup"
                        value-expr="id"
                        display-expr="id"
                        v-model="localContact.state"
                        :search-enabled="true"
                        :disabled="!hasCompanyAccess || readOnly"
                    />
                </div>

                <div class="col-3 form-group">
                    <label for="txt_contact_zip">Zip</label>
                    <rq-masked-input id="txt_contact_zip" type="text" placeholder="Zip" maskType="zip" v-model="localContact.zip" />
                </div>

                <div class="col-3 form-group">
                    <label for="txt_contact_license">License</label>
                    <input id="txt_contact_license" automation_id="txt_contact_license" type="text" placeholder="License" class="form-control " v-model="localContact.contactStateLicenseID" />
                </div>

                <div class="col-3 form-group">
                    <label for="drp_contact_state_issued">State Issued</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_contact_state_issued', id: 'drp_contact_state_issued' }"
                        :items="usStatesLookup"
                        value-expr="id"
                        display-expr="id"
                        v-model="localContact.stateIssuedContactLicense"
                        :disabled="!hasCompanyAccess || readOnly"
                    />
                </div>

                <div class="col-3 form-group">
                    <label for="txt_contact_nmls_id">NMLS ID</label>
                    <input id="txt_contact_nmls_id" automation_id="txt_contact_nmls_id" type="text" class="form-control" placeholder="NMLS ID" v-model="localContact.contactNMLSID" />
                </div>

                <div class="col-3 form-group" :class="{ 'has-error':v$.localContact.phone.$error }">
                    <label for="txt_contact_business">Business</label>
                    <rq-masked-input id="txt_contact_business" placeholder="Business Phone" v-model="v$.localContact.phone.$model" maskType="phone" isMasked="true"></rq-masked-input>
                    <rq-validation-feedback>Business phone number format is invalid.</rq-validation-feedback>
                </div>
                <div class="col-3 form-group">
                    <label for="txt_contact_extension">Extension</label>
                    <input id="txt_contact_extension" automation_id="txt_contact_extension" type="text" placeholder="Extension" class="form-control" mask="#######" v-model="localContact.phoneExtension" />
                </div>
                <div class="col-3 form-group" :class="{ 'has-error':v$.localContact.cellPhone.$error }">
                    <label for="txt_contact_mobile">Mobile</label>
                    <rq-masked-input id="txt_contact_mobile" placeholder="Mobile Phone" v-model="v$.localContact.cellPhone.$model" maskType="phone" isMasked="true"></rq-masked-input>
                    <rq-validation-feedback>Mobile phone number format is invalid.</rq-validation-feedback>
                </div>
                <div class="col-3 form-group" :class="{ 'has-error':v$.localContact.direct.$error }">
                    <label for="txt_contact_direct">Direct</label>
                    <rq-masked-input id="txt_contact_direct" placeholder="Direct" v-model="v$.localContact.direct.$model" maskType="phone" isMasked="true"></rq-masked-input>
                    <rq-validation-feedback>Direct phone number format is invalid.</rq-validation-feedback>
                </div>
                <div class="col-3 form-group" :class="{ 'has-error':v$.localContact.fax.$error }">
                    <label for="txt_contact_fax">Fax</label>
                    <rq-masked-input id="txt_contact_fax" placeholder="Fax" v-model="v$.localContact.fax.$model" maskType="phone" isMasked="true"></rq-masked-input>
                    <rq-validation-feedback>Fax phone number format is invalid.</rq-validation-feedback>
                </div>
                <div class="col-3 form-group" :class="{ 'has-error':v$.localContact.home.$error }">
                    <label for="txt_contact_home">Home</label>
                    <rq-masked-input id="txt_contact_home" placeholder="Home Phone" cssClass="form-control" v-model="v$.localContact.home.$model" maskType="phone" isMasked="true"></rq-masked-input>
                    <rq-validation-feedback>Home phone number format is invalid.</rq-validation-feedback>
                </div>
                <div class="col-3 form-group" :class="{ 'has-error':v$.localContact.other.$error }">
                    <label for="txt_contact_other">Other</label>
                    <rq-masked-input id="txt_contact_other" placeholder="Other" v-model="v$.localContact.other.$model" maskType="phone" isMasked="true"></rq-masked-input>
                    <rq-validation-feedback>Other phone number format is invalid.</rq-validation-feedback>
                </div>
                <div class="col-3 form-group">
                    <label for="drp_contact_sales_representative">Sales Representative</label>
                    <dx-select-box
                        :input-attr="{ id:'drp_contact_sales_representative', automation_id:'drp_contact_sales_representative' }"
                        :items="salesRepsLookup"
                        value-expr="id"
                        display-expr="name"
                        v-model="localContact.salesRepresentativeStaffID"
                    />
                </div>
            </div>
        </fieldset>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { useVModel } from '@vueuse/core';
    import { AddressHelper } from '@/shared/utilities';
    
    //Since the phone numbers are being masked to be "(xxx) xxx-xxxx" and limiting character input, we simply have to validate the value's length (14 characters)
    const phoneNumberValid = value => _.isEmpty(value) || value.length === 14;

    export default {
        name: 'ContactInfo',
        props: {
            contact: { type: Object, default: () => ({})  },
            hasCompanyAccess: { type: Boolean, default: true },
            readOnly: { type: Boolean, default: false },
            companyTitle: { type: String }
        },
        data() {
            return {
                addressDataSource: {},
                addressInputAttr: {},
                addressContentReady: false,
                isAddress1Focused: false,
            };
        },
        setup(props, {emit}){
            const v$ = useVuelidate();
            const localContact = useVModel(props, "contact", emit);

            return{
                v$,
                localContact
            }
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                orderState: state => state.orders.order,
                properties: state => state.properties.properties,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "usStatesLookup"
            ]),
            addMode() { return this.localContact.contactID === 0;  },
            isValid() { return !this.v$.$error; },
            salesRepsLookup(){
                return this.lookupHelpers.getOrderLookupItems(this.lookupItems.SALES_REPS);
            },
            allCounties() { return this.lookupHelpers.getLookupItems('counties'); },
        },
        watch: {
            "v$.localContact.$error": {
                handler(newValue, oldValue){ this.$emit('validation-event', this.generateValidationData()); }
            },
            contact(newValue, oldValue) { this.reset(); }
        },
        validations: () => ({
            localContact: {
                firstName: { required },
                lastName: { required },
                phone: { phoneNumberValid },
                cellPhone: { phoneNumberValid },
                direct: { phoneNumberValid },
                fax: { phoneNumberValid },
                home: { phoneNumberValid },
                other: { phoneNumberValid },
            },
        }),
        created() {
            const self = this;
            self.initAddressAutocomplete();
        },
        methods: {
            validate() { this.v$.$touch(); },
            reset() { this.v$.$reset(); },
            generateValidationData() {
                return { isValid: this.isValid };
            },
            initAddressAutocomplete() {
                const self = this;
                self.addressDataSource = {
                    key: "text",
                    load(loadOptions){
                        let propertyState = !_.isEmpty(self.properties) ? self.properties[0].property.state : "";
                        return self.$api.UtilitiesApi.addressAutoComplete(loadOptions.searchValue, self.localContact.state || propertyState);
                    }
                };
                self.addressInputAttr = {
                    autocomplete: Math.random(),
                    automation_id: "txt_contact_address1",
                };
            },
            onAddressContentReady(e) {
                const self = this;
                if (self.addressContentReady) return;
                self.addressContentReady = true;
            },
            onAddress1FocusIn(e){
                this.isAddress1Focused = true;
            },

            onAddress1FocusOut(e){
                this.isAddress1Focused = false;
            },
            onAddress1Opened(e){
                if(this.isAddress1Focused || !this.$refs.addressSigningAutocomplete) return;
                this.setAddressAutocompleteOption("opened", false);
            },

            onAutoCompleteSelectionChanged(e) {
                const self = this;

                let selectedItem = e.component.option("selectedItem");

                if(!_.isObject(selectedItem)) return;

                self.updateAddress({
                    address1: selectedItem.address1,
                    address2: selectedItem.address2,
                    city: selectedItem.city,
                    state: selectedItem.state,
                    zip: selectedItem.zip
                }, false);

                // Then call subsequent validation.
                self.validateAddress();
            },
            validateAddress(property={}, showToast=true) {
                const self =  this;

                let entity = !_.isEmpty(property) ? property : self.mapAddressForValidation();

                self.isProcessingAddress = true;

                return self.$api.UtilitiesApi
                    .verifyAddress(entity)
                    .then((response) => {

                        if(!response.isVerified) {
                            self.localContact.isValidatedAddress = false;
                            if(showToast)
                            {
                                self.$toast.error("Invalid Address");
                            }
                            return false;
                        }

                        self.updateAddress(response, true);
                        return true;
                    })
                    .catch((error) => {
                        self.localContact.isValidatedAddress = false;
                        if(showToast)
                        {
                            self.$toast.error("Invalid Address");
                        }
                        return false;
                    })
                    .finally(() => {
                        self.isProcessingAddress = false;
                    })
            },
            onAutoCompleteValueChanged(e) {
                if(_.isNil(e.event)) return;
                const self = this;

                self.resetValidatedAddress();
            },
            resetValidatedAddress(e) {
                const self = this;

                self.localContact.isValidatedAddress = false;
            },
            updateAddress(property, isAddressValidated) {
                const self = this;

                self.localContact.address1 = property.address1;
                self.localContact.address2 = property.address2;
                self.localContact.city = property.city;
                self.localContact.state = property.state;
                self.localContact.zip = property.zip;
                self.localContact.countyID = property.countyId;
                self.localContact.countyName = property.county;

                self.updateCounty();

                self.localContact.isValidatedAddress = isAddressValidated;
            },
            updateCounty() {
                let self = this;
                let county = null;
                if (!_.isNil(self.localContact.countyID)) {
                    county = _.find(self.allCounties, { id: self.localContact.countyID });
                }
                else if (!_.isNil(self.localContact.countyName)) {
                    county = _.find(self.allCounties, (county) => 
                        AddressHelper.isCountyNameSame(county.name, self.localContact.countyName) && 
                        county.data.toLowerCase().trim() === self.localContact.state.toLowerCase().trim()
                    );
                }

                if (!county) {
                    self.localContact.countyName = "";
                }
                else {
                    self.localContact.countyName = county.name;
                    self.localContact.countyID = county.id;
                }
            },
            onValidateAddress() {
                const self = this;

                self.$rqBusy.wait(self.validateAddress(), { targetElement: _.get(self, "$refs.imgContainerElement", null) });
            },
            mapAddressForValidation() {
                const self = this;

                return {
                    address1: self.localContact.address1,
                    address2: self.localContact.address2,
                    city: self.localContact.city,
                    state: self.localContact.state,
                    zip: self.localContact.zip
                }
            },
            copyCompanyAddress() {
                const self = this;
                let apiPromise = self.$api.CompaniesApi.getCompany(self.localContact.companyID);
                return self.$rqBusy.wait(apiPromise)
                    .then(company => {
                        self.localContact.address1 = company.address1;
                        self.localContact.address2 = company.address2;
                        self.localContact.city = company.city;
                        self.localContact.state = company.state;
                        self.localContact.zip = company.zip;
                    });
            },
        }
    }
</script>
